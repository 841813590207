<template>
  <Page
    hide-header
    hide-footer
    is-divider
    container-class="is-aligned-bottom"
    class="CoverPage"
    sidebar-title="Cover Page"
    :name="key"
    data-testid="CoverPage"
  >
    <div class="CoverPage is-position-relative m-b-l">
      <div class="CoverPage__wrapper">
        <div
          class="is-text-primary CoverPage__heading has-text-weight-bold"
          data-testid="CoverPage-BrandName"
        >
          {{ brand.name }}
        </div>

        <div
          class="is-size-2 m-b-xxl"
          data-testid="CoverPage-Subtitle"
        >
          {{ $t('pages.measure.media_coverage_report', language) }}
        </div>

        <table class="CoverPage__metaTable">
          <tr data-testid="CoverPage-DatePeriod">
            <td>{{ $t('components.title_page.period', language) }}:</td>
            <td>
              {{ datetime(appliedCoverageFilters.start * 1000, 'DD.MM.YYYY') }}
              -
              {{ datetime(appliedCoverageFilters.end * 1000, 'DD.MM.YYYY') }}
            </td>
          </tr>

          <tr data-testid="CoverPage-ReportMarkets">
            <td>
              {{ $t('general.markets', language) }}:
            </td>
            <td>
              <div class="is-flex">
                <delimited-list
                  #default="{ val: market }"
                  :value="marketsToDisplay"
                  property="name"
                >
                  <span>{{ $t(market.name, language) }}</span>
                </delimited-list>
              </div>
            </td>
          </tr>

          <tr data-testid="CoverPage-Tags">
            <td>
              {{ $t('general.tags', language) }}
            </td>

            <td data-testid="ReportTags">
              <delimited-list
                #default="{ val: tag }"
                :value="tagsToDisplay"
                property="title"
              >
                <span>{{ $t(tag.title, language) }}</span>
              </delimited-list>
            </td>
          </tr>

          <tr data-testid="CoverPage-Reputation">
            <td>
              {{ $t('general.sentiment', language) }}
            </td>
            <td>
              <delimited-list
                #default="{ val: sentiment }"
                :value="sentimentToDisplay"
              >
                <span>{{ $t(sentiment.label, language) }}</span>
              </delimited-list>
            </td>
          </tr>

          <tr data-testid="CoverPage-MediaType">
            <td>
              {{ $t('general.media_type', language) }}
            </td>
            <td>
              <delimited-list
                #default="{ val: type }"
                :value="mediaTypesToDisplay"
              >
                <span>{{ $t(type.label, language) }}</span>
              </delimited-list>
            </td>
          </tr>

          <tr data-testid="CoverPage-Tier">
            <td>
              {{ $t('general.tier', language) }}
            </td>
            <td>
              <delimited-list
                #default="{ val: type }"
                :value="tiersToDisplay"
              >
                <span>{{ $t(type.label, language) }}</span>
              </delimited-list>
            </td>
          </tr>
        </table>
      </div>

      <div class="logo-wrap m-t-l">
        <div class="log-wrap__hf-logo">
          <div class="has-text-weight-bold is-size-7">
            {{ $t('components.title_page.powered_by', language) }}
          </div>
          <img
            :src="$asset(logoPath)"
            class="hf-logo"
            alt="Hypefactors"
          >
        </div>

        <div v-if="reporterLogo" class="log-wrap__custom-reporter-logo">
          <img
            :src="reporterLogo"
            class="reporter-logo"
            alt="Hypefactors"
          >
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import { isColorDark } from '@hypefactors/shared/js/utils/colorUtils'

import Page from '@/components/measure/Page'

export default {
  name: 'CoverPage',

  components: {
    DelimitedList,
    Page
  },

  mixins: [DatetimeMixin],

  data () {
    return {
      key: 'cover_page'
    }
  },

  computed: {
    ...mapGetters('coverage', [
      'appliedCoverageFilters',
      'marketsToDisplay',
      'tagsToDisplay',
      'mediaTypesToDisplay',
      'sentimentToDisplay',
      'tiersToDisplay'
    ]),

    ...mapGetters('measure', [
      'report',
      'language',
      'primaryColor',
      'reporterLogo'
    ]),

    ...mapGetters({
      brand: 'activeBrand'
    }),

    isPrimaryColorDark (state, getters) {
      return isColorDark(this.primaryColor)
    },

    logoPath () {
      // the default is black any way
      if (!this.primaryColor) {
        return '/images/branding/hf_logo_full_white.svg'
      }

      return this.isPrimaryColorDark
        ? '/images/branding/hf_logo_full_white.svg'
        : '/images/branding/hf_logo_full_dark.svg'
    }
  }
}
</script>

<style lang="scss">
.CoverPage {
  &__heading {
    font-size: $size-1 * 1.5;
  }

  &__wrapper {
    display: inline-block;
    max-width: 75%;
    margin-left: 8rem;
    padding: 2rem 4rem;
  }

  &__metaTable {
    font-size: $size-4;

    tr > td:first-of-type {
      font-weight: bold;
      padding-left: 0;
    }

    td {
      padding: $padding-small $padding-medium;
    }
  }

  .logo-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 8rem;
    padding-left: 4rem;

    .reporter-logo {
      width: 250px;
    }

    .hf-logo {
      width: 120px;
    }
  }
}
</style>
