var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"CoverPage",attrs:{"hide-header":"","hide-footer":"","is-divider":"","container-class":"is-aligned-bottom","sidebar-title":"Cover Page","name":_vm.key,"data-testid":"CoverPage"}},[_c('div',{staticClass:"CoverPage is-position-relative m-b-l"},[_c('div',{staticClass:"CoverPage__wrapper"},[_c('div',{staticClass:"is-text-primary CoverPage__heading has-text-weight-bold",attrs:{"data-testid":"CoverPage-BrandName"}},[_vm._v(" "+_vm._s(_vm.brand.name)+" ")]),_c('div',{staticClass:"is-size-2 m-b-xxl",attrs:{"data-testid":"CoverPage-Subtitle"}},[_vm._v(" "+_vm._s(_vm.$t('pages.measure.media_coverage_report', _vm.language))+" ")]),_c('table',{staticClass:"CoverPage__metaTable"},[_c('tr',{attrs:{"data-testid":"CoverPage-DatePeriod"}},[_c('td',[_vm._v(_vm._s(_vm.$t('components.title_page.period', _vm.language))+":")]),_c('td',[_vm._v(" "+_vm._s(_vm.datetime(_vm.appliedCoverageFilters.start * 1000, 'DD.MM.YYYY'))+" - "+_vm._s(_vm.datetime(_vm.appliedCoverageFilters.end * 1000, 'DD.MM.YYYY'))+" ")])]),_c('tr',{attrs:{"data-testid":"CoverPage-ReportMarkets"}},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('general.markets', _vm.language))+": ")]),_c('td',[_c('div',{staticClass:"is-flex"},[_c('delimited-list',{attrs:{"value":_vm.marketsToDisplay,"property":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var market = ref.val;
return [_c('span',[_vm._v(_vm._s(_vm.$t(market.name, _vm.language)))])]}}])})],1)])]),_c('tr',{attrs:{"data-testid":"CoverPage-Tags"}},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('general.tags', _vm.language))+" ")]),_c('td',{attrs:{"data-testid":"ReportTags"}},[_c('delimited-list',{attrs:{"value":_vm.tagsToDisplay,"property":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tag = ref.val;
return [_c('span',[_vm._v(_vm._s(_vm.$t(tag.title, _vm.language)))])]}}])})],1)]),_c('tr',{attrs:{"data-testid":"CoverPage-Reputation"}},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('general.sentiment', _vm.language))+" ")]),_c('td',[_c('delimited-list',{attrs:{"value":_vm.sentimentToDisplay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var sentiment = ref.val;
return [_c('span',[_vm._v(_vm._s(_vm.$t(sentiment.label, _vm.language)))])]}}])})],1)]),_c('tr',{attrs:{"data-testid":"CoverPage-MediaType"}},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('general.media_type', _vm.language))+" ")]),_c('td',[_c('delimited-list',{attrs:{"value":_vm.mediaTypesToDisplay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var type = ref.val;
return [_c('span',[_vm._v(_vm._s(_vm.$t(type.label, _vm.language)))])]}}])})],1)]),_c('tr',{attrs:{"data-testid":"CoverPage-Tier"}},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('general.tier', _vm.language))+" ")]),_c('td',[_c('delimited-list',{attrs:{"value":_vm.tiersToDisplay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var type = ref.val;
return [_c('span',[_vm._v(_vm._s(_vm.$t(type.label, _vm.language)))])]}}])})],1)])])]),_c('div',{staticClass:"logo-wrap m-t-l"},[_c('div',{staticClass:"log-wrap__hf-logo"},[_c('div',{staticClass:"has-text-weight-bold is-size-7"},[_vm._v(" "+_vm._s(_vm.$t('components.title_page.powered_by', _vm.language))+" ")]),_c('img',{staticClass:"hf-logo",attrs:{"src":_vm.$asset(_vm.logoPath),"alt":"Hypefactors"}})]),(_vm.reporterLogo)?_c('div',{staticClass:"log-wrap__custom-reporter-logo"},[_c('img',{staticClass:"reporter-logo",attrs:{"src":_vm.reporterLogo,"alt":"Hypefactors"}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }