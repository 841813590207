<template>
  <div class="PageFooter">
    <div class="container is-fluid">
      <div class="is-flex is-aligned-justify is-aligned-middle">
        <div class="PoweredBy">
          <div class="has-text-weight-bold is-size-7">
            {{ $t('components.title_page.powered_by', language) }}
          </div>

          <img :src="$asset('/images/branding/hf_logo_full_dark.svg')" width="100" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>

<style lang='scss'>
.PageFooter {
  padding: .5rem 1.5rem;
}
</style>
