<template>
  <div
    class="Period"
    data-testid="Period"
  >
    {{ datetime(appliedCoverageFilters.start * 1000, 'DD.MM.YYYY') }}
    -
    {{ datetime(appliedCoverageFilters.end * 1000, 'DD.MM.YYYY') }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

export default {
  mixins: [DatetimeMixin],

  computed: {
    ...mapGetters('coverage', ['appliedCoverageFilters'])
  }
}
</script>
