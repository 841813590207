<template>
  <Page
    class="MetricsMap"
    :sidebar-title="$t('pages.measure.metrics_map.sidebar_title', language)"
    :sidebar-content="$t('pages.measure.metrics_map.sidebar_content', language)"
    :name="key"
    data-testid="MetricsMap"
  >
    <FactsOnMarketsDataProvider
      :fetch-when="bootstrapped"
      class="Page__content-body"
    >
      <PageTitle>{{ $t('pages.measure.top_clippings.title', language) }}</PageTitle>

      <CoverageKeyMetricsMapChart
        slot-scope="{ totals }"
        :locale="language"
        :totals="totals"
      />
    </FactsOnMarketsDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseSection from './BaseSection'
import CoverageKeyMetricsMapChart from '@hypefactors/shared/js/components/charts/extended/keyMetrics/CoverageKeyMetricsMapChart'
import FactsOnMarketsDataProvider from '@hypefactors/shared/js/components/charts/extended/factsOnMarkets/FactsOnMarketsDataProvider'

import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'MetricsMap',

  components: {
    Page,
    PageTitle,
    CoverageKeyMetricsMapChart,
    FactsOnMarketsDataProvider
  },

  extends: BaseSection,

  data () {
    return {
      key: 'metrics_map'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
