<template>
  <div class="PageHeader">
    <div class="container is-fluid">
      <div class="is-flex is-aligned-justify is-aligned-middle PageHeader__content">
        <div class="PageHeader__left-part is-flex is-aligned-middle">
          <round-avatar
            :src="brand.logo.cropped || brand.logo.original"
            :acronym-fallback="brand.name"
            size="large"
          />

          <div class="p-l-m is-size-4 has-text-weight-bold">
            {{ brand.name }}
          </div>
        </div>

        <div class="PageHeader__right-part has-text-right has-text-black">
          <p class="is-size-4 is-uppercase tracking-wider">
            <span>
              {{ $t('pages.measure.media_coverage_report', language) }}
            </span>
          </p>

          <PageHeaderPeriod />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RoundAvatar from '@hypefactors/shared/js/components/core/RoundAvatar'

import PageHeaderPeriod from './PageHeaderPeriod.vue'

export default {
  components: {
    RoundAvatar,
    PageHeaderPeriod
  },

  computed: {
    ...mapGetters({
      brand: 'activeBrand'
    }),

    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>

<style lang='scss'>
.PageHeader {
  padding: .5rem 1.5rem;

  &__content {
    border-bottom: 1px solid $grey-light;
    padding-bottom: .5rem;
  }
}
</style>
