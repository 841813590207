<template>
  <Page
    class="Branding"
    :sidebar-title="$t('pages.measure.logo_and_product_displayed.sidebar_title', language)"
    :sidebar-content="$t('pages.measure.logo_and_product_displayed.sidebar_0', language)"
    :name="key"
    data-testid="Branding"
  >
    <PageTitle>{{ $t('pages.measure.logo_and_product_displayed.title', language) }}</PageTitle>

    <CoverageBrandingMetricsDataProvider
      :fetch-when="bootstrapped"
      class="Page__content-body"
      :locale="language"
    />
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import CoverageBrandingMetricsDataProvider from '@hypefactors/shared/js/components/charts/extended/brandingMetrics/CoverageBrandingMetricsDataProvider'

import BaseSection from './BaseSection'

import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'Branding',

  components: {
    Page,
    PageTitle,
    CoverageBrandingMetricsDataProvider
  },

  extends: BaseSection,

  data () {
    return {
      key: 'branding'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
