import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      brand: 'activeBrand'
    }),
    ...mapState('coverage', ['bootstrapped'])
  }
}
