<template>
  <div
    class="FactsOnMarketsCharts"
    data-testid="FactsOnMarketsCharts"
  >
    <div class="columns">
      <div class="column">
        <div
          ref="hypefactorsvalue"
          class="hf-value-chart chart"
        />
      </div>

      <div class="column">
        <div
          ref="clippings"
          class="clippings-chart chart"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <div
          ref="impressions"
          class="impressions-chart chart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { moneyFormatter, moneyCategoryFormatter, abbrFormatter } from '@hypefactors/shared/js/components/charts/TooltipFormatter'
import BarChart from '@hypefactors/shared/js/components/charts/base/BarChart'

export default {
  name: 'FactsOnMarketsCharts',

  props: {
    chartValues: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      $clippingsChart: null,
      $impressionsChart: null,
      $hypefactorsValueChart: null
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  },

  watch: {
    chartValues: 'redrawCharts'
  },

  mounted () {
    this.initHypefactorsValueChart()
    this.initClippingsChart()
    this.initImpressionsChart()
  },

  methods: {
    initHypefactorsValueChart () {
      const data = this.extractSeries(this.chartValues.hfValue, 'hypefactors_value')

      this.$hypefactorsValueChart = new BarChart(
        this.$refs.hypefactorsvalue,
        data.categories,
        data.series,
        {
          monochrome: true,
          title: this.$t('general.hypefactors_value', this.language),
          yAxisLabel: this.$currencySymbol(),
          formatter: moneyFormatter,
          tooltipFormatter: moneyCategoryFormatter,
          height: 300,
          pointWidth: 20,
          marginLeft: 120,
          marginRight: 50,
          dataLabels: {
            crop: false,
            overflow: 'none',
            inside: false
          }
        }
      )
    },

    initClippingsChart () {
      const data = this.extractSeries(this.chartValues.clippings, 'clippings')

      this.$clippingsChart = new BarChart(
        this.$refs.clippings,
        data.categories,
        data.series,
        {
          monochrome: true,
          title: this.$t('general.clippings', this.language),
          height: 300
        }
      )
    },

    initImpressionsChart () {
      const data = this.extractSeries(this.chartValues.impressions, 'impressions')

      this.$impressionsChart = new BarChart(
        this.$refs.impressions,
        data.categories,
        data.series,
        {
          monochrome: true,
          title: this.$t('general.impressions', this.language),
          height: 300,
          formatter: abbrFormatter
        }
      )
    },

    extractSeries (data, seriesName) {
      const keys = Object.keys(data)

      return {
        categories: keys,
        series: [{
          data: keys.map(key => {
            const value = data[key].current
            if (seriesName === 'hypefactors_value') {
              return this.$store.getters.convertCurrency(value)
            }

            return value
          }),
          name: this.$t(`general.${seriesName}`, this.language)
        }]
      }
    },

    redrawCharts (newVal) {
      const clippingSeries = this.extractSeries(newVal.clippings, 'clippings')
      const impressionsSeries = this.extractSeries(newVal.impressions, 'impressions')
      const hypefactoresValueSeries = this.extractSeries(newVal.hfValue, 'hypefactors_value')

      this.$clippingsChart.update(clippingSeries.series, undefined, clippingSeries.categories)
      this.$impressionsChart.update(impressionsSeries.series, undefined, impressionsSeries.categories)
      this.$hypefactorsValueChart.update(hypefactoresValueSeries.series, undefined, hypefactoresValueSeries.categories)
    }
  }
}
</script>
