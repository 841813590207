<template>
  <Page
    class="MediaCategory"
    :sidebar-title="$t('pages.measure.media_categories.sidebar_title', language)"
    :sidebar-content="$t('pages.measure.media_categories.sidebar_0', language)"
    :name="key"
    data-testid="MediaCategoryByImpressions"
  >
    <PageTitle>{{ $t('pages.measure.media_categories.by_impressions.title', language) }}</PageTitle>

    <CoverageMediaCategoryDataProvider :fetch-when="bootstrapped" :type="type">
      <template v-slot:default="provider">
        <div
          v-loading="provider.isLoading"
          class="CoverageMediaCategoryDataProvider Page__content-body"
        >
          <MediaCategorySplitCharts
            :chart-values="provider.chartData"
            :empty-chart-values="provider.emptyChartData"
            :locale="language"
            :type="type"
          />
        </div>
      </template>
    </CoverageMediaCategoryDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import CoverageMediaCategoryDataProvider from '@hypefactors/shared/js/components/charts/extended/mediaCategory/CoverageMediaCategoryDataProvider'
import MediaCategorySplitCharts from '@hypefactors/shared/js/components/charts/extended/mediaCategory/MediaCategorySplitCharts'

import BaseSection from './BaseSection'
import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'MediaCategoryByImpressions',

  components: {
    Page,
    PageTitle,
    CoverageMediaCategoryDataProvider,
    MediaCategorySplitCharts
  },

  extends: BaseSection,

  data () {
    return {
      key: 'media_category_impressions',
      type: 'impressions'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
