<template>
  <Page
    class="MediaType"
    :sidebar-title="$t('general.media_types', language)"
    :sidebar-content="$t('pages.measure.media_type_split.by_hf_value.sidebar_0', language)"
    :name="key"
    data-testid="MediaTypeByHfValue"
  >
    <PageTitle>{{ $t('pages.measure.media_type_split.by_hf_value.title', language) }}</PageTitle>

    <CoverageMediaTypeMetricsDataProvider :fetch-when="bootstrapped">
      <template v-slot:default="provider">
        <div
          v-loading="provider.isLoading"
          class="CoverageMediaTypeMetricsDataProvider Page__content-body"
        >
          <MediaTypeSplit
            :chart-values="provider.chartValues"
            :empty-country-data="provider.emptyCountryData"
            :filled-country-data="provider.filledCountryData"
            :locale="language"
          />
        </div>
      </template>
    </CoverageMediaTypeMetricsDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseSection from './BaseSection'

import CoverageMediaTypeMetricsDataProvider
  from '@hypefactors/shared/js/components/charts/extended/mediaTypeMetrics/CoverageMediaTypeMetricsDataProvider'
import MediaTypeSplit
  from '@hypefactors/shared/js/components/charts/extended/mediaTypeMetrics/MediaTypeSplit'

import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'MediaTypeByHfValue',

  components: {
    Page,
    PageTitle,
    CoverageMediaTypeMetricsDataProvider,
    MediaTypeSplit
  },

  extends: BaseSection,

  data () {
    return {
      key: 'media_type'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
