<template>
  <Page
    class="KeyMetrics"
    :sidebar-title="$t('pages.measure.facts_overview.sidebar_title', language)"
    :name="key"
    data-testid="KeyMetricsByImpressions"
  >
    <template #sidebarContent>
      <p class="m-b-m">
        <template v-if="hasMarkets">
          {{ $t('pages.measure.facts_overview.sidebar_0', language, { brand: brand.name, markets: commaSeparatedMarkets }) }}
        </template>
        <template v-else>
          {{ $t('pages.measure.facts_overview.sidebar_0_no_markets', language, { brand: brand.name }) }}
        </template>

        {{ datetime(appliedCoverageFilters.start * 1000, 'DD.MM.YYYY') }}
        -
        {{ datetime(appliedCoverageFilters.end * 1000, 'DD.MM.YYYY') }}
      </p>

      <p class="m-b-m">
        {{ $t('pages.measure.facts_overview.sidebar_1_by_impressions', language, { brand: brand.name }) }}
      </p>

      <p class="m-b-m">
        {{ $t('pages.measure.facts_overview.sidebar_2', language) }}
      </p>

      <p class="m-b-m">
        {{ $t('pages.measure.facts_overview.sidebar_3', language) }}
      </p>

      <p class="m-b-m">
        {{ $t('pages.measure.facts_overview.sidebar_4', language) }}
      </p>
    </template>

    <PageTitle>
      {{ $t('general.impressions', language) }}
    </PageTitle>

    <CoverageKeyMetricsSectionDataProvider :fetch-when="bootstrapped" :type="type">
      <template v-slot:default="provider">
        <div class="Page__content-body">
          <SummaryAreaChartTotalsSection
            :locale="language"
            :type="type"
            :can-change-type="canChangeType"
            :is-fetching-timeseries="provider.isFetchingTimeseries"
            :is-fetching-totals="provider.isFetchingTotals"
            :timeseries="provider.timeseries[type]"
            :total-clippings="provider.totals.clippings.totals"
            :total-hf-value="provider.totals.hfValue.totals"
            :total-impressions="provider.totals.impressions.totals"
          />
        </div>
      </template>
    </CoverageKeyMetricsSectionDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import CoverageKeyMetricsSectionDataProvider from '@hypefactors/shared/js/components/charts/extended/keyMetrics/CoverageKeyMetricsSectionDataProvider'
import SummaryAreaChartTotalsSection from '@hypefactors/shared/js/components/charts/extended/keyMetrics/SummaryAreaChartTotalsSection'

import BaseSection from './BaseSection'
import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'KeyMetricsByImpressions',

  components: {
    Page,
    PageTitle,
    CoverageKeyMetricsSectionDataProvider,
    SummaryAreaChartTotalsSection
  },

  extends: BaseSection,

  mixins: [DatetimeMixin],

  data () {
    return {
      key: 'key_metrics_impressions',
      type: 'impressions',
      canChangeType: false
    }
  },

  computed: {
    ...mapGetters('coverage', [
      'hasMarkets',
      'appliedCoverageFilters',
      'marketsToDisplay'
    ]),

    ...mapGetters('measure', [
      'language'
    ]),

    commaSeparatedMarkets () {
      return this.marketsToDisplay.map(market => this.$t(market.name, this.language)).join(', ')
    }
  }
}
</script>
