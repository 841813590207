<template>
  <Page
    :sidebar-title="$t('pages.measure.top_clippings.sidebar_title', language)"
    class="TopClippings"
    :sidebar-content="$t('pages.measure.top_clippings.sidebar_0', language, {brand: brand.name})"
    :name="key"
    data-testid="TopClippings"
  >
    <PageTitle>{{ $t('pages.measure.top_clippings.title', language) }}</PageTitle>

    <CoverageTopClippingsDataProvider
      #default="{ data: clippings }"
      :fetch-when="bootstrapped"
      :locale="language"
      class="Page__content-body"
    >
      <TopClippingsTable
        :clippings="clippings"
        :locale="language"
      />
    </CoverageTopClippingsDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import CoverageTopClippingsDataProvider from '@hypefactors/shared/js/components/tables/CoverageTopClippingsDataProvider'
import TopClippingsTable from '@hypefactors/shared/js/components/tables/TopClippings'

import BaseSection from './BaseSection'
import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'TopClippings',

  components: {
    Page,
    PageTitle,
    TopClippingsTable,
    CoverageTopClippingsDataProvider
  },

  extends: BaseSection,

  data () {
    return {
      key: 'top_clippings'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
