<template>
  <Page
    :name="`clipping_${clipping.id}`"
    :page-name="clipping.data.headline || fallbackPageName"
    data-testid="ClippingPage"
  >
    <ClippingPreview
      :clipping="clipping"
      :locale="language"
    />
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import ClippingPreview from '@hypefactors/shared/js/components/clippings/ClippingPreview'
import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

import Page from '@/components/measure/Page'

export default {
  name: 'ClippingPage',

  components: {
    Page,
    ClippingPreview
  },

  props: {
    clipping: {
      type: Object,
      required: true
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ]),

    fallbackPageName () {
      return this.$t(MEDIA_TYPES[this.clipping.type].label, this.language) + ' ' + this.$t('general.clipping', this.language)
    }
  }
}
</script>
