<template>
  <PageDivider
    class="ClippingsDivider"
    :page-name="$t('pages.measure.clippings_separator', language)"
    :config="config"
    data-testid="MediaClippings"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import PageDivider from './PageDivider'

export default {
  name: 'ClippingsDivider',

  components: { PageDivider },

  data () {
    return {
      name: 'clipping_preview_divider'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ]),

    config () {
      return {
        id: this.name,
        is_hidden: false,
        text_overrides: {
          headline: this.$t('pages.measure.media_clippings', this.language),
          body: ''
        }
      }
    }
  }
}
</script>
