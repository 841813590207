<template>
  <h2 class="PageTitle title is-2">
    <slot />
  </h2>
</template>

<script>
export default {}
</script>

<style lang="scss">
.title.PageTitle {
  margin-top: $margin-large;
}
</style>
