<template>
  <section
    :class="classes"
    :style="styles"
    :data-testid="`Page--${name}`"
    class="Page"
  >
    <div v-if="isVisible" class="Page__wrapper" :class="{ 'm-h-a': canEdit }">
      <PageHeader v-if="!hideHeader" />

      <div
        :class="[containerClass, { 'is-aligned-middle': verticalCenter }]"
        class="Page__body container is-fluid"
        data-testid="PageBody"
      >
        <div class="columns is-variable-grid is-6">
          <div v-if="!isDivider && (sidebarTitle || sidebarContent)" class="column is-3 is-2-desktop is-flex">
            <div class="p-a-m is-full-width Page__sidebar">
              <div class="icon m-t-xl">
                <VIcon
                  type="comma"
                  size="2x"
                  fallback
                />
              </div>

              <h4 class="title is-4 has-text-weight-bold has-text-inherit">
                {{ sidebarTitle }}
              </h4>

              <div class="has-text-weight-semibold is-size-6">
                <slot name="sidebarContent">
                  {{ sidebarContent }}
                </slot>
              </div>
            </div>
          </div>

          <div class="Page__content column">
            <slot />
          </div>
        </div>
      </div>

      <PageFooter v-if="!hideFooter" />
    </div>

    <div v-if="canEdit" class="Page__settings has-background-black p-a-m">
      <hide-single-component
        v-if="name"
        :name="name"
        :page-name="pageName || sidebarTitle"
        :is-visible="isVisible"
        @change="scrollToHiddenPage"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import HideSingleComponent from './core/HideSingleComponent'

import PageHeader from './PageHeader'
import PageFooter from './PageFooter'

export default {
  components: {
    VIcon,
    HideSingleComponent,
    PageHeader,
    PageFooter
  },
  props: {
    size: {
      type: Number,
      default: 3
    },
    verticalCenter: {
      type: Boolean,
      default: false
    },
    hasBranding: {
      type: Boolean,
      default: true
    },
    isFluid: {
      type: Boolean,
      default: true
    },
    sidebarTitle: {
      type: String,
      default: ''
    },
    sidebarContent: {
      type: String,
      default: ''
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    isDivider: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    /**
     * The name to display when the page is hidden
     */
    pageName: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters('measure', ['isPageVisible']),

    canEdit () {
      return this.$route.name === 'measure.manage'
    },

    isVisible () {
      return this.isPageVisible(this.name)
    },

    classes () {
      return {
        [`A${this.size}`]: true,
        hasHeader: !this.hideHeader,
        hasFooter: !this.hideFooter,
        'Page--isDivider': this.isDivider,
        'Page--isHidden': !this.isVisible,
        'm-v-l has-hs-auto Page--isEditing': this.canEdit
      }
    },
    styles () {
      return {
        '--THEME__COLOR-PRIMARY': '', // use the local theme color
        '--THEME__COLOR-PRIMARY-INVERT': '' // use the local text color
      }
    }
  },
  methods: {
    async scrollToHiddenPage () {
      await this.$nextTick()

      this.$el.scrollIntoView()
    }
  }
}
</script>

<style lang='scss'>
$width__A3: 420mm;

.Page {
  position: relative;
  overflow: hidden;
  page-break-after: always;
  margin: 0;
  border: initial;
  border-radius: initial;
  width: initial;
  min-height: initial;
  box-shadow: initial;
  background: initial;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  background: $hf__color-white;

  &--isHidden {
    page-break-after: auto;
  }

  &.A3 .Page__wrapper {
    width: $width__A3;
    height: 296mm;
  }

  &--isEditing .Page__wrapper {
    width: 100% !important;
  }

  .Page__wrapper {
    align-items: stretch;
    display: flex;
    max-height: 100%;
    position: relative;
    flex-flow: column;
    // TODO: Not sure about this. Copied from CoverPage
    height: 100%;
    padding: 3rem 1.5rem;
  }

  .Page__body {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1 1 auto;

    > * {
      flex: 1 1 auto;
    }
  }

  .Page__sidebar {
    @include customPropertyFallback(background-color, --THEME__COLOR-PRIMARY, $grey-lighter);
    @include customPropertyFallback(color, --THEME__COLOR-PRIMARY-INVERT, $hf__color-gray-darker);
  }

  &.Page--isDivider .Page__body {
    @include customPropertyFallback(background-color, --THEME__COLOR-PRIMARY, $hf__color-black);
    @include customPropertyFallback(color, --THEME__COLOR-PRIMARY-INVERT, $hf__color-white);

    h3 {
      margin-left: 5rem;
    }
  }

  &.hasHeader .Page__wrapper {
    padding-top: 0;
  }

  &.hasFooter .Page__wrapper {
    padding-bottom: 0;
  }

  .Page__content {
    display: flex;
    flex-flow: column;

    &-body {
      margin: auto !important;
      width: 100%;
    }

    > *:not(.columns) {
      width: 100%;
    }

    .chart {
      width: 100%;

      .highcharts-container {
        width: 100% !important;

        > svg.highcharts-root {
          width: 100% !important;
        }
      }
    }
  }

  &.is-centered .Page__content {
    display: flex;
    align-items: center;
  }

  &__settings {
    min-width: $width__A3;
  }
}
</style>
