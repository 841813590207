<template>
  <Page
    class="Sentiment"
    :sidebar-title="$t('pages.measure.sentiment.sidebar_title', language)"
    :sidebar-content="$t('pages.measure.sentiment.sidebar_0', language)"
    :name="key"
    data-testid="Reputation"
  >
    <PageTitle>{{ $t('pages.measure.sentiment.title', language) }}</PageTitle>

    <CoverageSentimentDataProvider
      :fetch-when="bootstrapped"
      :locale="language"
      class="Page__content-body"
    />
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import CoverageSentimentDataProvider from '@hypefactors/shared/js/components/charts/extended/sentimentMetrics/CoverageSentimentDataProvider'

import BaseSection from './BaseSection'
import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'Sentiment',

  components: {
    Page,
    PageTitle,
    CoverageSentimentDataProvider
  },

  extends: BaseSection,

  data () {
    return {
      key: 'sentiment'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
