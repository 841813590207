<template>
  <Page
    class="FactsMarkets"
    :sidebar-title="$t('pages.measure.facts_markets.sidebar_title', language)"
    :name="key"
    data-testid="FactsOnMarkets"
  >
    <div slot="sidebarContent">
      <p class="m-b-m">
        {{ $t('pages.measure.facts_markets.sidebar_0', language) }}
      </p>
      <p class="m-b-m">
        {{ $t('pages.measure.facts_markets.sidebar_1', language) }}
      </p>
    </div>

    <PageTitle>{{ $t('general.hypefactors_value', language) }}. {{ $t('general.impressions', language) }}. {{ $t('general.clippings', language) }}</PageTitle>

    <FactsOnMarketsDataProvider
      #default="{ totals }"
      :fetch-when="bootstrapped"
      class="Page__content-body"
    >
      <FactsOnMarketsCharts :chart-values="totals" />
    </FactsOnMarketsDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import FactsOnMarketsDataProvider from '@hypefactors/shared/js/components/charts/extended/factsOnMarkets/FactsOnMarketsDataProvider'

import BaseSection from './BaseSection'
import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'
import FactsOnMarketsCharts from '@/components/measure/FactsOnMarketsCharts'

export default {
  name: 'FactsMarkets',

  components: {
    Page,
    PageTitle,
    FactsOnMarketsCharts,
    FactsOnMarketsDataProvider
  },

  extends: BaseSection,

  data () {
    return {
      key: 'facts_markets'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
