<template>
  <div class="HideSingleComponent" data-testid="HideSingleComponent">
    <label
      class="has-text-decoration-underline is-cursor-pointer has-text-weight-bold"
      data-testid="HideSingleComponentTrigger"
      @click="toggle"
    >
      <template v-if="isVisible">
        <div class="icon align-middle">
          <VIcon type="eye-slash" fallback class="filter-invert" />
        </div>

        {{ $t('components.hide_single_component.hide_this') }}
      </template>
      <template v-else>
        {{ $t('components.hide_single_component.include_page') }}<span v-if="!isVisible && pageName">: {{ pageName }}</span>
      </template>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import VIcon from '@hypefactors/shared/js/components/core/VIcon'

export default {
  name: 'HideSingleComponent',

  components: {
    VIcon
  },

  props: {
    name: {
      type: String,
      required: true
    },

    pageName: {
      type: String,
      default: ''
    },

    isVisible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapActions('measure', ['toggleSinglePage']),

    toggle () {
      this.toggleSinglePage(this.name)

      this.$emit('change')
    }
  }
}
</script>
