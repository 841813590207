<template>
  <Page
    :name="config.id"
    :page-name="pageName"
    is-divider
    class="PageDivider"
    container-class="is-aligned-middle is-aligned-center"
  >
    <div class="m-l-xxxl p-l-xl">
      <Heading size="1" color="inherit">
        {{ headline }}
      </Heading>
      <div
        v-if="body"
        class="m-t-m"
        v-html="body"
      />
    </div>
  </Page>
</template>

<script>
import Heading from '@hypefactors/shared/js/components/core/Heading'

import Page from '@/components/measure/Page'

export default {
  name: 'PageDivider',

  components: {
    Heading,
    Page
  },

  props: {
    pageName: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({
        id: 'some_id',
        type: 'page-divider',
        order: 0,
        hidden: false,
        options: {
          background_image: null,
          background_color: null,
          font_color: null,
          headline_font_size: null,
          text_font_size: null
        },
        text_overrides: {
          headline: '',
          body: ''
        }
      })
    }
  },

  computed: {
    headline () {
      return this.config.text_overrides.headline
    },

    body () {
      return this.config.text_overrides.body
    }
  }
}
</script>
